// @flow

import * as React from 'react'

type Props = {
  src?: string,
  width?: string,
}

export const Video = ({ src, ...props }: Props) => {
  return (
    <video src={src} muted autoPlay loop {...props}>
      <source src={src} type="video/mp4" />
      <p>
        Your browser doesn't support HTML5 video. Here is a{' '}
        <a href="myVideo.mp4">link to the video</a> instead.
      </p>
    </video>
  )
}
